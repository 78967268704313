import * as React from 'react';
import {connect} from "react-redux";
import CardBody from "reactstrap/es/CardBody";
import {Button, Form, Input} from "reactstrap";
import classnames from "classnames";
import api from "../../../../api";
import {toast} from "react-toastify";
import {FormattedMessage} from "react-intl";

function CollectorImport(props) {
	const {collector} = props;
	const [file, setFile] = React.useState(null);
	const [saving, setSaving] = React.useState(false);
	const [collectorResponses, setCollectorResponses] = React.useState(collector.collectorResponses)

	console.log(props)
	React.useEffect(() => {
	}, [])

	const handleUploadFile = ((e) => {
		setFile(e.target.files[0])
	})

	const onSubmit = ((e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('file', file);

		api.surveyCollector.post.importAnkieteo(collector, formData).then((data) => {
			setCollectorResponses(data.collectorResponses)
			toast.success("Udało się poprawnie zaimportować wywiady");
		})
	})

	const clearFile = (() => {
		setFile(null);
		document.getElementById('import-survey-collector-input').value = "";
	})

	console.log(file)

	return (
		<CardBody>
			<h1>Wywiadów w kolektorze: {collectorResponses}</h1>
			<Form id="import-survey-collector" className="d-flex justify-content-center">
				<Input
					id={"import-survey-collector-input"}
					name="import-survey-collector"
					type="file"
					placeholder="Import wywiadów w kolektorze"
					onChange={handleUploadFile}
				/>
			</Form>
			<div>
				<Button onClick={(e) => onSubmit(e)} color={"primary"} className={classnames({"disabled": !file, "mr-4": true})}>Zapisz</Button>
				<Button color={"secondary"} className={classnames({"disabled": !file})} onClick={clearFile}>Usuń plik</Button>
			</div>
		</CardBody>
	);
}

CollectorImport.propTypes = {};

function mapStateToProps(state) {
	return {}
}

export default connect(mapStateToProps, {})(CollectorImport);