import React, {Component} from 'react';
import PropTypes from "prop-types";
import LoadingSpinner from "components/LoadingSpinner";
import CollectorName from "./Collector/CollectorName";
import CollectorEmail from "./CollectorEmail/CollectorEmail";
import CollectorWebLink from "./CollectorWebLink/CollectorWebLink";
import CollectorBaseSurveys from "./CollectorBaseSurveys/CollectorBaseSurveys";
import CollectorPanel from "./CollectorPanel/CollectorPanel";
import CollectorTokens from "./CollectorTokens/CollectorTokens";
import CollectorTopSurveys from "./CollectorTopSurveys/CollectorTopSurveys";
import CollectorTest from "./CollectorTest/CollectorTest";
import CollectorWidget from "./CollectorWidget/CollectorWidget";
import history from "services/history";
import {Link} from "react-router-dom";
import appRoutes from "appRoutes";
import api from 'api';
import CardHeader from "reactstrap/es/CardHeader";
import {Card} from "reactstrap";
import CollectorsButtonPaused from "../../Survey/Collectors/components/CollectorsButtonPaused";
import {toast} from "react-toastify";
import moment from "moment";
import CollectorSms from "./CollectorSms/CollectorSms";
import {connect} from "react-redux";
import CollectorImport from "./CollectorImport/CollectorImport";

class SurveyCollector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      collector: null
    };

    this.collectorByType = this.collectorByType.bind(this);
    this.updateCollector = this.updateCollector.bind(this);
    this.updateCollectorName = this.updateCollectorName.bind(this);
  }

  componentDidMount(){
    this.setState({
      loading: true
    });


    api.surveyCollector.get.collector({
      id: this.props.match.params.collector
    }).then(collector => {
      if(collector.type === 'Panel'){
        if(this.props.plan.survey.collectorPanelVerifyRequired){
          history.push(appRoutes.survey.collectors.listType(this.props.match.params.survey, 'Panel'));
          toast.warn('Brak dostępu do edycji tego kolektora')
        }
      }

      this.setState({
        loading: false,
        collector: collector
      });
    }).catch(e => {
      toast.error(e.response.data.errors[0].message);
      this.props.history.push(appRoutes.surveys.list);
    });
  }

  collectorByType(type){
    switch (type) {
      case 'Email':
        return <CollectorEmail collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
      case 'WebLink':
        return <CollectorWebLink collector={this.state.collector} handleUpdate={this.updateCollector}/>;
      case 'BaseSurveys':
        return <CollectorBaseSurveys collector={this.state.collector} handleUpdate={this.updateCollector}/>;
      case 'Panel':
        return <CollectorPanel collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
      case 'Test':
        return <CollectorTest collector={this.state.collector} handleUpdate={this.updateCollector}/>;
      case 'Tokens':
        return <CollectorTokens collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
      case 'TopSurveys':
        return <CollectorTopSurveys collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
      case 'Sms':
        return <CollectorSms collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
      case 'Widget':
        return <CollectorWidget collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
      case 'Import':
        return <CollectorImport collector={this.state.collector} handleUpdate={this.updateCollector} match={this.props.match}/>;
    }
  }

  updateCollector(collector) {

    this.setState({
      collector
    });
  }

  updateCollectorName(value) {
    this.setState({
      collector: {
        ...this.state.collector,
        name: value,
      }
    });
  }

  render() {
    const {loading, collector} = this.state;

    if(loading){
      return <LoadingSpinner/>;
    }

    return (
      <main>
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <CollectorName id={collector.id} collector={collector} handleUpdate={name => {
              api.surveyCollector.patch({
                id: collector.id,
                surveyCollector: {
                  name,
                }
              })
            }}/>
            <CollectorsButtonPaused paused={collector.paused} collector={collector} handleCollectorChange={(z, x, paused) => {}} className="float-right mt-1 mt-md-2"/>
          </CardHeader>
          <hr className="my-0" />
          {this.collectorByType(collector.type)}
        </Card>

        <Link history={history} to={appRoutes.survey.collectors.list(this.props.match.params.survey)}>Wróć do listy kolektorów</Link>
        <p className="float-right">Data utworzenia: {moment(collector.createdAt).format('YYYY-MM-DD HH:mm')}</p>
      </main>
    );
  }
}

SurveyCollector.propTypes = {
  plan: PropTypes.shape({
    survey: PropTypes.shape({
      collectorPanelVerifyRequired: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    plan: state.user.userPlan.plan,
  }
}

export default connect(mapStateToProps)(SurveyCollector);